import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import TrustPilot from "../components/TrustPilot";

import CorporateForm from "../pages/corporate-account/form";


export const CorporatePageTemplate = ({ image, title, heading, subheading, mainpitch, description, intro }) => (
  <div>
    <Helmet>
      <title>Swiftfox | Corporate an Account</title>
    </Helmet>
    <div className="hero-section home-hero corporat-acc">
      <div className="hero-main">
        <div className="hero-main-content">
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h1 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                  Open a Corporate Account
                </motion.h1>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  You can get an account for free and make payments  with the real exchange rate in 70+ countries.
                </motion.h4>
              )}
            </InView>
          </div>
          <div className="section-item">
            <InView threshold={0.3}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 1, delay: 1.2, ease: "easeInOut" }} className="hero-chart pushmeright ">
                  <div className="vndr-account">
                  <CorporateForm />
                  </div>
                </motion.div>
              )}
            </InView>
          </div>
        </div>
      </div>
      <div className="hero-banner">
        <InView threshold={0.1}>
          {({ ref, inView }) => (
            <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 1.25, ease: "easeInOut" }} className="hero-banner-content">
                <div className="section-item">
                  <p>We help ensure every penny is accounted for no matter where it goes - whether employees at home or suppliers abroad.</p>
                </div>
                <div className="section-item">
                  <div className="banner-images">
                    {/* <img alt="Trust Pilot" src="../img/trust-pilot.svg"/> */}
                  </div>
                </div>
            </motion.div>
          )}
        </InView>
      </div>
    </div>
    <div className="callout-section callout-bg dark-text">
      <div className="callout-main">
        <div className="callout-main-content">
          <div className="section-item white-text">
            <InView threshold={0.3}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                  Your expert currency exchange broker
                </motion.h2>
              )}
            </InView>
          </div>
          <div className="section-item">
            <InView threshold={0.3}>
              {({ ref, inView }) => (
                <motion.p ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  There are countless ways to transfer money abroad. The most important consideration is the timing and mode of a transaction. When you exchange currencies with Swiftfox, we help you find that sweet spot, reducing your risk and maximising returns.
                </motion.p>
              )}
            </InView>
          </div>
        </div>
      </div>
    </div>
    <div className="callout-section dark-bg white-text">
      <div className="callout-main">
        <div className="callout-main-content">
          <div className="section-item">
          <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }} className="module-stack widtho">
                    <TrustPilot />
                </motion.div>
              )}
            </InView>
          </div>
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  <span>Fast, Reliable and Secure.</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                  The security and privacy of your data are of the highest importance to us. That’s why we’ve partnered with The Currency Cloud Limited as our payment service provider. They are one of the world's top payment engines, designed to protect the safety and security of customers' money.
                </motion.h4>
              )}
            </InView>
          </div>
        </div>
      </div>
    </div>


  </div>
);

CorporatePageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const CorporatePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <CorporatePageTemplate image={frontmatter.image} title={frontmatter.title} heading={frontmatter.heading} subheading={frontmatter.subheading} mainpitch={frontmatter.mainpitch} description={frontmatter.description} intro={frontmatter.intro} />
    </Layout>
  );
};

CorporatePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default CorporatePage;

export const pageQuery = graphql`
  query CorporatePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
