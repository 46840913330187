import React from "react";
import { navigate } from "gatsby-link";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class CorporateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false, messageText : "Field is require." };
  }

  handleChange = (e) => {
    e.target.parentElement.classList.remove("requiredBol")
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    this.state.isValidatedFalse = false;
   
    if ( this.state.fname == null ||  this.state.fname == '' ){
      this.fname.parentElement.classList.add("requiredBol");
      this.fname.focus();
      this.setState({ isValidatedFalse : true, messageText : "First name field is required." });
      return false;
    }
    else if ( this.state.lname == null ||  this.state.lname == '' ){
      this.lname.parentElement.classList.add("requiredBol");
      this.lname.focus();
      this.setState({ isValidatedFalse : true, messageText : "Last name field is required." });
      return false;
    }
    else if ( this.state.email == null ||  this.state.email == '' ){
      this.email.parentElement.classList.add("requiredBol");
      this.email.focus();
      this.setState({ isValidatedFalse : true, messageText : "Email field is required." });
      return false;
    } else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email))){
      this.email.parentElement.classList.add("requiredBol");
      this.email.focus();
      this.setState({ isValidatedFalse : true, messageText : "Email is invalid." });
      return false;
    }
    else if ( this.state.phone == null ||  this.state.phone == ''){
      this.phone.parentElement.classList.add("requiredBol");
      this.phone.focus();
      this.setState({ isValidatedFalse : true, messageText : "Phone field is required." });
      return false;
    }


    delete this.state.isValidated;
    delete this.state.messageText;
    fetch("https://getform.io/f/4328135a-b9de-4252-bb6f-09f8770b5f0c", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    return (

          <div className="contact-container" id="scrollTopCont" ref={ input => { this.scrollTopCont = input; }}>
              <form
                name="Corporate Form"
                method="post"
                action="/thankyou"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
                autoComplete="off"
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="Corporate Form" />
                <div hidden>
                  <label>
                    Don’t fill this out:{" "}
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </div>
                <div className="holder">
                  <div className="field inputs">
                    <div className="control">
                      <input
                        className="input"
                        type={"text"}
                        name={"fname"}
                        id={"fname"}
                        ref={ input => { this.fname = input; }}
                        onChange={this.handleChange}
                        placeholder="First Name"
                        autoComplete="off"
                      />
                    </div>
                    <div className="control">
                      <input
                        className="input"
                        type={"text"}
                        name={"lname"}
                        id={"lname"}
                        ref={ input => { this.lname = input; }}
                        onChange={this.handleChange}
                        placeholder="Last Name"
                        autoComplete="off"
                      />
                    </div>
                    <div className="control">
                      <input
                        className="input"
                        type={"text"}
                        name={"email"}
                        id={"email"}
                        ref={ input => { this.email = input; }}
                        onChange={this.handleChange}
                        placeholder="Email Address"
                        autoComplete="off"
                      />
                    </div>
                    <div className="control">
                      <input
                        className="input"
                        type={"text"}
                        name={"phone"}
                        id={"phone"}
                        ref={ input => { this.phone = input; }}
                        onChange={this.handleChange}
                        placeholder="Phone Number"
                        autoComplete="off"
                      />
                    </div>
                    <div className="control">
                      <input
                        className="input"
                        type={"text"}
                        name={"bname"}
                        id={"bname"}
                        ref={ input => { this.bname = input; }}
                        onChange={this.handleChange}
                        placeholder="Business Name"
                        autoComplete="off"
                      />
                    </div>
                    <div className="control">
                      <input
                        className="input"
                        type={"radio"}
                        name={""}
                        onChange={this.handleChange}
                        id={"radio"}
                        required={true}
                        placeholder=""
                        autoComplete="off"
                      />
                      <p>I have read, understood and agree to the Terms and Conditions.</p>
                    </div>
                    <div className="control">
                      <input
                        className="input"
                        type={"radio"}
                        name={""}
                        onChange={this.handleChange}
                        id={"radio"}
                        required={true}
                        placeholder=""
                        autoComplete="off"
                      />
                      <p>By filling out this form, I agree to be contacted by a member of SwiftFox team to discuss or help with the account setup.</p>
                    </div>
                    <button className="main-button-alt is-link" type="submit">
                      Send It
                    </button>
                    { this.state.isValidatedFalse ? 
                    <div className="alert alert-danger" role="alert">
                      <p className="white-text">{ this.state.messageText } </p>
                    </div>
                    : null 
                    }
                  </div>
                </div>
              </form>
            </div>

    );
  }
}
